import { useStaticQuery, graphql } from "gatsby"

export const useLocationEdges = () => {

    const { allWordpressWpLocation } = useStaticQuery(
        graphql`
            query locationEdges {
                allWordpressWpLocation{
                    edges{
                        node{
                            title
                            acf {
                                office_location_textarea
                            }
                        }
                    }
                }
            }
        `
    )

    return allWordpressWpLocation.edges
}