export const loadScript = (src, id, async = true, defer = true) => {
  var tag = document.createElement('script');
  tag.id = id;
  tag.async = async;
  tag.defer = defer;
  tag.src = src;
  document.getElementsByTagName('body')[0].appendChild(tag);
};

export const unloadScript = id => {
  const scriptID = document.getElementById(id);

  if (scriptID) scriptID.parentNode.removeChild(scriptID);
};

// Return an array of random numbers
export const randomNumbersArray = total => {
  // create array of numbers from 0 to total
  let array = Array.from(new Array(total), (val, index) => index);
  // shuffle array
  // remove duplicates, while keeping length
  var j, x, i;
  for (i = array.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = array[i];
    array[i] = array[j];
    array[j] = x;
  }
  return array;
};

// TODO - consolidate page and post title into single function

export function pageTitle({ page, site }) {
  const {
    slug,
    title,
    acf: { seo_page_title }
  } = page;
  const _pageTitle = seo_page_title ? seo_page_title : title;
  return slug === 'home'
    ? `${site} | ${_pageTitle}`
    : `${_pageTitle} | ${site}`;
}

export function postTitle({ post, site }) {
  const {
    slug,
    title,
    acf: { seo_page_title }
  } = post;
  const _title = seo_page_title ? seo_page_title : title;
  return slug === 'home' ? `${site} | ${_title}` : `${_title} | ${site}`;
}

export function phoneToTel(phone) {
  return '+1' + phone.replace(/[^a-zA-Z0-9]/g, '');
}

export function requestFullscreen(ele) {
  if (ele.requestFullscreen) {
    ele.requestFullscreen();
  } else if (ele.webkitRequestFullscreen) {
    ele.webkitRequestFullscreen();
  } else if (ele.mozRequestFullScreen) {
    ele.mozRequestFullScreen();
  } else if (ele.msRequestFullscreen) {
    ele.msRequestFullscreen();
  } else {
    console.log('Fullscreen API is not supported.');
  }
}

export function exitFullscreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  } else {
    console.log('Fullscreen API is not supported.');
  }
}

export function isMobileDevice() {
  return (
    typeof window.orientation !== 'undefined' ||
    navigator.userAgent.indexOf('IEMobile') !== -1
  );
}
