import React from "react"
import { useLocationEdges} from "../hooks/use-location-edges"

export default () => {
	const locations = useLocationEdges();

	return(
		<div className="locations">
	  { locations.length > 0 &&
	    locations.map( (location,i) => {
	      // let thisLocation = location.node.acf.office_location
	      let thisAddress = location.node.acf.office_location_textarea
	      thisAddress = thisAddress !== null ? thisAddress.replace(/\n/g, '<br />') : null
	      return(
	        <div key={i} className="location">
	          <span dangerouslySetInnerHTML={{__html: location.node.title}} /><br />
	          {thisAddress !== null && <span dangerouslySetInnerHTML={{__html: thisAddress}} />}
	        </div>
	      )
	    })
	  }
	  </div>
	)
}


/*
<span dangerouslySetInnerHTML={{__html: location.node.title}} /><br />
  {thisLocation.street1.trim() !== "" &&
    <span dangerouslySetInnerHTML={{__html: thisLocation.street1}} />

  }
  {thisLocation.street2.trim() !== "" &&
    <span dangerouslySetInnerHTML={{__html: ', ' + thisLocation.street2}} />
  }
  {thisLocation.city.trim() !== "" &&
    <span dangerouslySetInnerHTML={{__html: '<br />' + thisLocation.city}} />
  }
  {thisLocation.state.trim() !== "" &&
    <span dangerouslySetInnerHTML={{__html: ', ' + thisLocation.state}} />
  }
  {thisLocation.zip.trim() !== "" &&
    <span dangerouslySetInnerHTML={{__html: ' ' + thisLocation.zip}} />
  }
*/