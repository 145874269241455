const ToggleMenu = () => {
  // console.log('toggle')

  // const button = document.getElementById('toggle')
  const menu = document.getElementById("menu");
  const header = document.getElementById("header");
  const toggles = document.getElementsByClassName("toggle");
  const masked = document.getElementsByClassName("logo-masked");

  let i;
  for (i = 0; i < masked.length; i++) {
    if (masked[i].classList.contains("open")) {
      masked[i].classList.remove("open");
    } else {
      masked[i].classList.add("open");
    }
  }

  let j;
  for (j = 0; j < toggles.length; j++) {
    if (toggles[j].classList.contains("open")) {
      toggles[j].classList.remove("open");
    } else {
      toggles[j].classList.add("open");
    }
  }

  if (menu.classList.contains("opened")) {
    menu.classList.remove("opened");
    header.classList.remove("open");
  } else {
    menu.classList.add("opened");
    header.classList.add("open");
  }
};

export default ToggleMenu;
export { ToggleMenu };
