import React, { Component } from 'react';
import { Link } from 'gatsby';
import { HeaderContext } from '../context/HeaderContext';

import logoBlack from '../images/logo-black.svg';
import logoWhite from '../images/logo-white.svg';

class Logo extends Component {
  render() {
    const props = this.props;
    const context = this.context;
    const initLogoColor =
      context && context.state.initLogoColor
        ? context.state.initLogoColor
        : 'black';
    const logoColor =
      context && context.state.logoColor
        ? context.state.logoColor
        : initLogoColor;

    const className = ''; //props.masked === 'true' ? 'logo-masked' : '';
    return (
      <div className={className}>
        <div className={`logo ${logoColor}`}>
          <Link to='/'>
            <img src={logoBlack} alt={props.title} id='logo-black' />
            <img src={logoWhite} alt={props.title} id='logo-white' />
          </Link>
        </div>
      </div>
    );
  }
}

Logo.contextType = HeaderContext;

export default Logo;
