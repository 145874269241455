import { useStaticQuery, graphql } from "gatsby"

export const useSiteMetadata = () => {

    const { site } = useStaticQuery(
        graphql`
            query siteMetaData {
                site{
                    siteMetadata {
                        title
                        footer
                        siteUrl
                        contactEmail
                        contactPhone
                        twitterHandle
                    }
                }
            }
        `
    )

    return site.siteMetadata
}