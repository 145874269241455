import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'gatsby';
import detectIt from 'detect-it';
import Nav from './Navigation';
import Logo from './Logo';
import { HeaderContext } from '../context/HeaderContext';
import { useSiteMetadata } from '../hooks/use-site-metadata';
import Toggle from './Toggle';

export default function Header(props) {
  const [prevScrollpos, setPrevScrollpos] = useState(() => {
    const initialState = getPageYOffset();
    return initialState;
  });
  const [visible, setVisible] = useState(true);
  const [transparent, setTransparent] = useState(true);
  const context = useContext(HeaderContext);
  
  useEffect( () => {
    if (detectIt.passiveEvents === true) {
      window.addEventListener('scroll', handleScroll, {
        capture: false,
        passive: true
      });
    } else {
      window.addEventListener('scroll', handleScroll, false);
    }

    return function cleanup() {
      window.removeEventListener('scroll', handleScroll);
    }
  });

  // Hide or show the menu.
  function handleScroll() {
    if (!window) return;

    const currentScrollPos = getPageYOffset();
    const visible = prevScrollpos < 0 ? 1 : prevScrollpos > currentScrollPos;
    const transparent = currentScrollPos < 300;

    if (transparent && context.state.initLogoColor === 'light') {
      context.setLogoLight();
    } else {
      context.setLogoDark();
    }

    context.setVisibleUI(visible);

    setPrevScrollpos(currentScrollPos);
    setVisible(visible);
    setTransparent(transparent);

  };

  function getPageYOffset() {
    return typeof window !== `undefined` ? window.pageYOffset : 0;
  }

    const { title } = useSiteMetadata();
    const path = context ? context.state.path : '';

    let headerClass = 'header';
    if (!visible) {
      headerClass += ' header--hidden';
    }
    if (transparent) {
      headerClass += ' header--transparent';
    }
    return (
      <header id='header' className={headerClass}>
        <Logo title={title} />
        {context && context.state.visibleBackToBlog && (
          <Link to='/news' className='back-to-blog'>
            <i className='fas fa-arrow-left' />
            <div className='text'>
              All <span>Articles</span>
            </div>
          </Link>
        )}
        <Toggle />
        <Nav path={path} />
      </header>
    );
}