import React from "react";
import { StaticQuery, Link, graphql } from "gatsby";
import Logo from "./Logo";
import { ToggleMenu } from "./utils/MenuFunctions";
import Locations from "./Locations";
import SocialIcons from "./SocialIcons";

const Navigation = ({ ...props }) => {
  const data = props.data;
  const navLinks = data.wordpressWpApiMenusMenusItems.items;

  return (
    <nav>
      <div id="menu">
        <Logo />
        <div
          className="overlay"
          role="button"
          aria-label="Close navigation"
          tabIndex="0"
          onClick={(e) => ToggleMenu(e)}
          onKeyDown={(e) => ToggleMenu(e)}
        />
        <div className="section-menu">
          <div className="content">
            <div className="left">
              <ul>
                {navLinks.map((link, i) => {
                  const thisPath = props.path;
                  const activeBlog =
                    thisPath.includes("/news") || thisPath.includes("/news/")
                      ? "active"
                      : "";
                  const linkClass =
                    link.object_slug === "news" ? activeBlog : "";

                  return (
                    <li key={i}>
                      {link.object_slug === "work" ? (
                        <a href="https://media.monks.com/work-inventory">
                          {link.title}
                        </a>
                      ) : link.object_slug === "about" ? (
                        <a href="https://media.monks.com/what-we-do">
                          {link.title}
                        </a>
                      ) : link.object_slug === "approach" ? (
                        <a href="https://media.monks.com/what-we-do">
                          {link.title}
                        </a>
                      ) : link.object_slug === "culture" ? (
                        <a href="https://media.monks.com/careers">
                          {link.title}
                        </a>
                      ) : link.object_slug === "careers" ? (
                        <a href="https://media.monks.com/careers">
                          {link.title}
                        </a>
                      ) : link.object_slug === "news" ? (
                        <a href="https://media.monks.com/thinking">
                          {link.title}
                        </a>
                      ) : link.object_slug === "contact" ? (
                        <a href="https://media.monks.com/connect">
                          {link.title}
                        </a>
                      ) : link.type !== "custom" ? (
                        <Link
                          to={`/${link.object_slug}/`}
                          onClick={(e) => ToggleMenu(e)}
                          activeClassName="active"
                          className={linkClass}
                        >
                          {link.object_slug} {link.title}
                        </Link>
                      ) : (
                        <a href={`${link.url}`}>{link.title}</a>
                      )}
                      {link.wordpress_children !== null && (
                        <ul className="subnav">
                          {link.wordpress_children &&
                            link.wordpress_children.map((subitem) => (
                              <li key={i}>
                                {subitem.object_slug === "giving-back" ? (
                                  <a href="https://media.monks.com">
                                    {subitem.title}
                                  </a>
                                ) : link.type !== "custom" ? (
                                  <Link
                                    to={`/${link.object_slug}/${subitem.object_slug}`}
                                    activeClassName="active"
                                  >
                                    {subitem.title}
                                  </Link>
                                ) : (
                                  <a href={`${link.url}`}>{link.title}</a>
                                )}
                              </li>
                            ))}
                        </ul>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="right">
              <h6
                style={{
                  color: "#ffffff",
                  paddingRight: "1em",
                  marginBottom: "1.25em",
                }}
              >
                With seven offices in four countries and 300+ employees,
                <span style={{ color: "#ff3300", whiteSpace: "nowrap" }}>
                  {" "}
                  Firewood Marketing
                </span>{" "}
                is ready to partner.
              </h6>
              <SocialIcons />
              <Locations />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        wordpressWpApiMenusMenusItems(name: { eq: "New Nav" }) {
          count
          items {
            object_slug
            title
            type
            url
            wordpress_children {
              object_slug
              title
              type
              url
            }
          }
        }
      }
    `}
    render={(data) => <Navigation data={data} {...props} />}
  />
);
