import React from 'react';

import icon_twitter from '../images/social-icons/twitter.svg';
import icon_facebook from '../images/social-icons/facebook-f.svg';
import icon_linkedin from '../images/social-icons/linkedin-in.svg';
// import icon_google from "../images/social-icons/google-plus-g.svg";
import icon_instagram from '../images/social-icons/instagram.svg';

const SocialIcons = () => {
  return (
    <div className="social-icons">
      <a
        href="https://www.linkedin.com/company/2130872/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={icon_linkedin} alt="LinkedIn" />
      </a>
      <a
        href="https://www.instagram.com/firewoodmarketing/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={icon_instagram} alt="Instagram" />
      </a>
      <a
        href="https://www.facebook.com/Firewoodmarketing"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={icon_facebook} alt="Facebook" />
      </a>

      {/* <a href="https://plus.google.com/+Firewoodmarketing" target="_blank" rel="noopener noreferrer">
				<img src={icon_google} alt="Google" />
			</a> */}
      <a
        href="https://twitter.com/firewoodmkt"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={icon_twitter} alt="Twitter" />
      </a>
    </div>
  );
};

export default SocialIcons;
