import React, { useContext } from 'react';
import { StaticQuery, Link, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Locations from './Locations';
import SocialIcons from './SocialIcons';
import { phoneToTel } from '../utils/helpers';
import ThumbFPO from '../images/thumb-fpo.png';
import { HeaderContext } from '../context/HeaderContext';


const Footer = ({ data }) => {
  const isBlogCategory = useContext(HeaderContext);

  const acfMetaData = data.wordpressAcfOptions.options;
  let posts = data.allWordpressPost.edges;
  let isLatest = 'Latest';

  if( isBlogCategory && isBlogCategory.state.category){
    posts = posts.filter(function(post){
      return post.node.categories[0].slug === isBlogCategory.state.category
    })
    isLatest = isLatest + ' in ' + posts[0].node.categories[0].name;
  }

  return (
    <footer className='section-body footer'>
      <div id='footer-top' className='content'>
        <div className='content--main'>
          <div id='latest-posts' className='full'>
            <h6 className='eyebrow'>{isLatest}</h6>
            <div className='posts'>
              {posts.slice(0, 4).map(({ node }) => {
                const {
                  categories,
                  date,
                  featured_media,
                  id,
                  slug,
                  title
                } = node;
                const categorySlug = categories[0].slug.toLowerCase();

                return (
                  <div key={`footer-${id}`} className='post'>
                    <Link
                      to={`/news/${categorySlug}/${slug}`}
                      css={{ textDecoration: `none` }}
                    >
                      {featured_media ? (
                        <img
                          src={`${
                            featured_media.localFile.childCloudinaryAsset.fluid.src
                          }`}
                          className='image-responsive'
                          alt=''
                        />
                      ) : (
                        <img
                          src={ThumbFPO}
                          className='image-responsive'
                          alt=''
                        />
                      )}
                      <p className='date'>{date}</p>
                      <p
                        className='description'
                        dangerouslySetInnerHTML={{
                          __html: title
                        }}
                      />
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>

          <div className='full'>
            <h6 className='eyebrow'>Locations</h6>
            <Locations />
          </div>

          <div className='contact-info full'>
            <h6 className='eyebrow'>Contact Us</h6>
            <p>
              <b>New business &amp; general inquiries:</b><br/> 
              <a href={`mailto:${acfMetaData.contact_email}`}>
                {acfMetaData.contact_email}
              </a>{' '}
              <span
                className='hide-mobile'
                dangerouslySetInnerHTML={{
                  __html: acfMetaData.contact_phone
                }}
              />
              <br className='show-mobile' />
              <a
                href={`tel:${phoneToTel(acfMetaData.contact_phone)}`}
                className='show-mobile'
              >
                {acfMetaData.contact_phone}
              </a>
            </p>
            <p><b>Press Inquiries:</b> <a href="mailto:pr@firewoodmarketing.com">pr@firewoodmarketing.com</a></p>
          </div>
        </div>
      </div>
      <div id='footer-bottom' className='content'>
        <div className='content--main'>
          <div id='copyright'>
            <p>
              &#xA9; {new Date().getFullYear()} {acfMetaData.footer}{' '}
              <Link to='/privacy-policy/'>Privacy Policy</Link>
            </p>
          </div>

          <SocialIcons />
        </div>
      </div>
    </footer>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      {
        allWordpressPost(sort: { fields: [date], order: DESC }) {
          edges {
            node {
              id
              title
              slug
              categories {
                name
                slug
              }
              date(formatString: "ddd MMM DD YYYY")
              featured_media {
                localFile {
                  childCloudinaryAsset {
                    fluid(maxWidth: 375, transformations: "w_375,ar_16:9,c_fill") {
                      ...CloudinaryAssetFluid
                    }
                  }
                }
              }
            }
          }
        }
        wordpressAcfOptions {
          ...acfMetaDataQuery
        }
      }
    `}
    render={data => <Footer data={data} {...props} />}
  />
);

Footer.propTypes = {
  data: PropTypes.shape({
    wordpressAcfOptions: PropTypes.shape({
      options: PropTypes.shape({
        contact_email: PropTypes.string.isRequired,
        contact_phone: PropTypes.string.isRequired,
        footer: PropTypes.string.isRequired
      }).isRequired
    }).isRequired
  }).isRequired
};
