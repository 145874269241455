import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";

const Banner = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [banner, setBanner] = useState(() => {
    const initialState = Cookies.get("banner");
    return initialState;
  });

  useEffect(() => {
    if (!banner || banner !== "hide") {
      setIsVisible(true);
    }
  }, [banner]);

  function closeBanner() {
    Cookies.set("banner", "hide", { expires: 1, path: "" });
    setBanner(Cookies.get("banner"));
    setIsVisible(false);
  }

  return (
    <div className={`banner ${isVisible}`}>
      <div className="content">
        {/* <p className="title">New Site Announcement</p> */}
        <p>
          Hello! Firewood is now part of Media.Monks, an S4Capital (SFOR.L)
          company. Please visit us at{" "}
          <a href="https://media.monks.com/">media.monks.com</a>. Thank you!
        </p>
        <div className="links">
          <a href="https://media.monks.com/" class="primary-link">
            Visit site
          </a>{" "}
          |{" "}
          <button href="#" onClick={closeBanner}>
            Hide banner
          </button>
        </div>
      </div>
    </div>
  );
};

export default Banner;
