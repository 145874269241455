import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { setConfig } from "react-hot-loader";

import Header from "../components/Header";
import Footer from "../components/Footer";
import Banner from "../components/Banner";
import "../styles/style.scss";

setConfig({ logLevel: "no-errors-please" });

class DefaultLayout extends Component {
  render() {
    return (
      <div className="wrapper">
        <Helmet>
          <link
            rel="stylesheet"
            href="https://use.fontawesome.com/releases/v5.1.1/css/all.css"
            integrity="sha384-O8whS3fhG2OnA5Kas0Y9l3cfpmYjapjI0E4theH4iuMD+pLhbf6JI0jIMfYcK3yZ"
            crossorigin="anonymous"
          />
        </Helmet>
        <Header />
        {this.props.children}
        <Banner />
        <Footer />
      </div>
    );
  }
}

export default DefaultLayout;

export const siteMetaDataQuery = graphql`
  fragment acfMetaDataQuery on wordpress__acf_options {
    options {
      contact_email
      contact_phone
      footer
    }
  }
`;
